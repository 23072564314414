.App {
    text-align: center;
    font-family: sans-serif;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/*1.2 - Pane that contains a table with each discipline button*/
.buttonpane {
    width: 100%;
    max-height: 50vh;
    background: linear-gradient(180deg, #0073b1 0%, rgba(240, 240, 240, 0) 100%);
}

/*1.2.a An Inner container to center the box*/
.buttonpane-inner {
    padding: 26px; /* Padding for all sides */
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 columns */
    grid-gap: 26px; /*Gap between buttons */
    overflow-y: auto;
    background: transparent; /* POOF! It's gone */
}

/*1.2.a.1 Defines each of the buttons for the disciplines*/
.disciplinebutton {
    aspect-ratio: 2.33; /* Maintain the aspect ratio */
    width: 100%; /* Allow the button to resize */
    cursor: pointer;
    margin: 0; /* Let the grid handle the spacing */
    background-size: cover; /* Cover the entire button area */
    background-repeat: no-repeat; /* No repeating, please */
    background-position: center; /* Center the image just right */
    border: 3px solid #002855;
    border-radius: 10px; /*Same as defined in Figma*/
}

/*1.1 Holds the Logo and the InfoPane*/
.logopane {
    background-image: url('./images/logo.png'); /* Logo URL */
    background-position: center center; /* Center the image */
    background-size: contain; /* Maintain aspect ratio and make sure the image fits within the container */
    background-repeat: no-repeat; /* Prevent the image from repeating */
    width: 100%; /* Full width */
    height: 50vh; /* Half of the viewport height */
    display: flex; /* Use flexbox to center contents */
    align-items: center; /* Align vertically */
    justify-content: center; /* Align horizontally */
}


/*1.1.a - Pane that contains the details of each discipline*/
.infopane {
    display: grid;
    grid-template-columns: 1fr 1fr; /* Two equal columns */
    gap: 10px;
    background: rgba(217, 217, 217, 0.8);
    /*width: 100%;*/
    height: 100%;
    /*max-height: 50vh;*/
}

/*1.1.a - image container within InfoPane, to the left of the infoText*/
.infoimagecontainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

/*1.1.a.1 - Actual Image*/
.infoimage {
    max-width: 95%; /* Ensures the image doesn't exceed the container's width */
    max-height: 95%;
    object-fit: contain; /* Maintains aspect ratio without cropping */
}

/*1.1.b - Text within InfoPane, to the right of the infoText*/
.infotext {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #002855;
    font-size: 40px;
    font-family: "Inter", sans-serif;
    font-weight: 400;
    word-wrap: break-word;
}

/*1 - Container for the whole desktop app*/
.desktopview {
    display: grid;
    grid-template-rows: 1fr 1fr; /* Split into two equal rows */
    height: 100vh; /* Full viewport height */
    
}

/* Additional styles for better practice and fallbacks */
.Infotext br {
    line-height: 1.2; /* Adjust as needed */
}

/* Responsive design for smaller screens */
@media (max-width: 712px) {
    
    /*1.1 Holds the Logo and the InfoPane*/
    .logoPane {
        background-position: middle center; /* Centered horizontally and aligned to the middle */
        background-size: 100vw auto; /* Here's the magic: auto for width, 50vh for height */
        background-repeat: no-repeat; /* Prevent image from repeating */    
    }
    
    
    .infopane {
        position: relative; /* Set relative positioning for .infopane */
        grid-template-columns: 1fr; /* Stack in one column on small screens */
    }

    .infoimagecontainer {
        width: 100%; /* Full width */
        grid-row-start: 1; /* Place the image in the first row */
        grid-column-start: 1; /* Place the image in the first column */
    }

    .infoimage {
        width: 100%; /* Full width of the container */
        height: auto; /* Maintain aspect ratio */
    }

    .infotext {
        position: absolute; /* Continue to use absolute positioning */
        top: 50%; /* Start by positioning at the center vertically */
        left: 50%; /* And also center it horizontally */
        transform: translate( -50%, -50% ); /* This little trick perfectly centers the text */
        width: 95%; /* Keeping the width within the image boundary */
        max-width: calc(100% - 10px); /* Ensure it doesn't overflow the image */
        padding: 5px; /* Add a little padding for aesthetic spacing */
        background: rgba(214, 228, 240, 0.5); /* Same semi-transparent background */
        color: #002855; /* And the same text color for that snazzy contrast */
        text-align: center; /* Center align the text for that extra pizzazz */
        /* Feel free to add any other styling properties */
    }

    .buttonpane {
        justify-items: center; /* Center items horizontally */
        grid-gap: 5px; /*Gap between buttons */
        overflow: hidden; /* Ensures the container doesn't scroll */
    }

    /*1.2.a An Inner container to center the box*/
    .buttonpane-inner {
        padding: 26px; /* Padding for all sides */
        display: grid;
        grid-gap: 26px; /*Gap between buttons */
        grid-template-columns: 1fr; /* This turns your 3-column grid into a single column */
        overflow-y: scroll; /* Enables vertical scrolling */
        max-height: 50vh; /* Set a max height to create a scrolling window */
    }

    /*1.2.a Defines each of the buttons for the disciplines*/
    .disciplinebutton {
        aspect-ratio: 2.33; /* Maintain the aspect ratio */
        font-size: clamp(10px, 5vw, 15px); /* Adjust these values as needed */
 
    }

    /*1 Container for the whole desktop app/
    /*TODO: ensure that the resizing of the loggo occurs BEFORE the loggo gets clipped on window resize.*/
    .desktopview {
        background-size: 100vw auto; /* Here's the magic: auto for width, 50vh for height */
    }


}

/* Ensure that the fonts fall back to a default if 'Inter' isn't available */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400&display=swap");


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
